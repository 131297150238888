.bulk_list_parent {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    align-items: center;
}

.bulk_list_parent .child_div {
    width: -webkit-fill-available;
}

.bulk_list_parent_expanded {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    align-items: center;
}

.bulk_expanded_list {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    justify-content: space-between;
}

.bulk_expanded_list_column {
    width: -webkit-fill-available;
}

.bulk_expanded_list .bulk_expanded_list_column:not(:last-of-type) {
    margin-right: 29px;
}

.bulk_element_with_status .bulk_element {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    border: 0.5px solid #DDDDDD;
    border-radius: 10px;
    box-shadow: 0px 3px 3px rgb(0, 0, 0, 0.1);
    min-height: 57px;
    min-width: 256px;
}

.bulk_element_with_status_selected .bulk_element {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    border: 0.5px solid #DDDDDD;
    border-radius: 10px;
    min-height: 57px;
    min-width: -webkit-fill-available;
    position: relative;
    z-index: 4;
}

.bulk_element {
    cursor: pointer;
}

.bulk_element .bulk_element_name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bulk_element .bulk_element_name .bulk_element_name_icon {
    display: flex;
    flex-direction: row;
}

.bulk_element .bulk_element_name .bulk_element_name_icon .bulk_name {
    display: flex;
    margin-left: 10px;
    margin-top: 8px;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 12px;
    letter-spacing: calc((10 / 1000) * 1em);
    color: #5D5D5D;
    align-items: center;
}

.bulk_element .bulk_element_name .bulk_element_name_icon .bulk_icon {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
}

.bulk_element .bulk_element_name .bulk_element_right_icon svg {
    width: 10px;
    height: 10px;
    margin-left: 5px;
}

.bulk_element .bulk_element_name .bulk_element_right_icon {
    margin-top: 10px;
    margin-right: 10px;
    font-size: 10px;
    font-family: "SF-Pro-Rounded-light";
}

.bulk_element .bulk_element_data {
    display: flex;
    flex-direction: row;
    margin-top: 9px;
    justify-content: space-between;
}

.bulk_element .bulk_element_data .bulk_element_country_data {
    display: flex;
    margin-left: 12px;
}

.bulk_element .bulk_element_data .bulk_element_detail_data {
    display: flex;
    margin-right: 10px;
}

.bulk_element .bulk_element_data .bulk_element_detail_data svg {
    display: flex;
    margin-right: 5px;
    margin-top: 3px;
}

.bulk_element .bulk_element_data .bulk_element_detail_data .products_order_count {
    display: flex;
    font-family: "SF-Pro-Rounded-regular";
    align-items: center;
    font-size: 10px;
    color: #5d5d5d;
    letter-spacing: calc((10 / 1000) * 1em);
}

.bulk_element .bulk_element_data .bulk_element_detail_data .bulk_element_weight {
    display: flex;
    font-family: "SF-Pro-Rounded-regular";
    align-items: center;
    font-size: 10px;
    color: #5d5d5d;
}

.bulk_element .bulk_element_data .bulk_element_detail_data .bulk_element_time {
    display: flex;
    flex-direction: row;
}

.bulk_element .bulk_element_data .bulk_element_detail_data .bulk_element_time .bulk_element_time_num {
    display: flex;
    font-family: "SF-Pro-Rounded-regular";
    align-items: center;
    font-size: 10px;
    color: #8E8E8E;
}

.bulk_element .bulk_element_data .bulk_element_detail_data .bulk_element_time .bulk_element_time_unit {
    display: flex;
    font-family: "SF-Pro-Rounded-light";
    align-items: center;
    font-size: 10px;
    color: #8E8E8E;
}

.bulk_element .bulk_element_data .bulk_element_detail_data svg:not(:first-child) {
    margin-left: 10px;
}

.bulk_element .bulk_element_data .bulk_element_country_data .bulk_element_country_icon {
    margin-top: 2px;
    display: flex;
    margin-right: 13px;
    width: 10px;
    height: 10px;
}

.bulk_element .bulk_element_data .bulk_element_country_data .bulk_element_country_name {
    display: flex;
    font-family: "SF-Pro-Rounded-regular";
    align-items: center;
    font-size: 10px;
    color: #5D5D5D;
    letter-spacing: calc((10 / 1000) * 1em);
}

.bulk_element_with_status_selected {
    margin-bottom: 11px;
}

.status_area_element {
    min-height: 42px;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: -webkit-fill-available;
    top: -9px;
    z-index: 2;
    cursor: pointer;
    animation-name: slideIn;
    animation-duration: 0.25s;
    animation-direction: normal;
}

@keyframes slideIn {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

.status_area_all_in {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 42px;
    display: flex;
    top: -9px;
    z-index: 2;
    cursor: pointer;
    width: -webkit-fill-available;
    position: relative;
    /* border-radius: 0px 0px 10px 10px; */
}

.status_area_element_with_order {
    min-height: 42px;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: -webkit-fill-available;
    top: -20px;
    z-index: 2;
    cursor: pointer;
}

.status_area_all_in_with_order {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 42px;
    display: flex;
    top: -20px;
    z-index: 2;
    cursor: pointer;
    width: -webkit-fill-available;
    position: relative;
    border-radius: 0px 0px 10px 10px;
}

.bulk_orders_area_approved {
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    top: -9px;
    z-index: 3;
    width: -webkit-fill-available;
    position: relative;
    border-radius: 0px 0px 10px 10px;
    background-color: #F4F8FF;
    min-height: calc((55 / 100) * 100vh);
}

.bulk_orders_area {
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    top: -9px;
    z-index: 3;
    width: -webkit-fill-available;
    position: relative;
    border-radius: 0px 0px 10px 10px;
    background-color: #EFEFEF;
    min-height: calc((55 / 100) * 100vh);
}

.status_area_all_in .status_area_all_element:first-child {
    margin-top: 18px;
}

.status_area_all_in_with_order .status_area_all_element:first-child {
    margin-top: 18px;
}

.status_area_all_element_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.status_area_all_element {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: -webkit-fill-available;
}

.bulk_element_with_status {
    margin-bottom: 10px;
}

.status_area_element_name {
    display: flex;
    flex-direction: row;
    margin-bottom: 9px;
    margin-left: 12px;
    margin-top: 19px;
    align-items: center;
}

.status_area_element_name_icon {
    margin-right: 5px;
}

.status_area_element_time {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    margin-top: 19px;
    margin-bottom: 9px;
    align-items: center;
}

.status_area_element_name_name {
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    color: #9A9A9A;
}

.status_area_element_time_time {
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    margin-right: 7px;
}


.status_area_element_name_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 9px;
    margin-left: 12px;
    align-items: center;
}

.status_area_element_name_icon_row {
    margin-right: 5px;
    min-width: 10px;
}

.status_area_element_time_row {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    margin-bottom: 9px;
    align-items: center;
}

.status_area_element_name_name_row {
    display: flex;
    flex-direction: row;
}

.status_area_element_name_name_row_statusname {
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    color: #C1C1C1;
}

.status_area_element_name_name_row_current {
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    color: #C1C1C1;
}

.status_area_element_name_name_row_username {
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    color: #C1C1C1;
}

.status_area_element_time_time_row {
    color: #C1C1C1;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    margin-right: 7px;
}

.status_area_all_in_with_order .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row .status_area_element_name_name_row_statusname {
    color: #5D5D5D;
}

.status_area_all_in_with_order .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row .status_area_element_name_name_row_username {
    color: #969696;
}

.status_area_all_in_with_order .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row_current {
    color: #969696;
}

.status_area_all_in_with_order .status_area_all_element:last-child .status_area_all_element_row .status_area_element_time_row .status_area_element_time_time_row {
    color: #8E8E8E;
}

.status_area_all_in_with_order .status_area_all_element:last-child .status_area_all_element_row:last-child .status_area_element_time_row .status_area_element_time_time_row {
    color: #5D5D5D;
}

.status_area_all_in .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row .status_area_element_name_name_row_statusname {
    color: #5D5D5D;
}

.status_area_all_in .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row .status_area_element_name_name_row_username {
    color: #969696;
}

.status_area_all_in .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row_current {
    color: #969696;
}

.status_area_all_in .status_area_all_element:last-child .status_area_all_element_row .status_area_element_time_row .status_area_element_time_time_row {
    color: #8E8E8E;
}

.status_area_all_in .status_area_all_element:last-child .status_area_all_element_row:last-child .status_area_element_time_row .status_area_element_time_time_row {
    color: #5D5D5D;
}

.bulk_orders_area_element:last-child {
    margin-bottom: 5px;
}

.bulk_orders_area_element:first-child {
    margin-top: 13px;
}

.bulk_orders_area_element_approved:last-child {
    margin-bottom: 5px;
}

.bulk_orders_area_element_approved:first-child {
    margin-top: 13px;
}

.bulk_orders_area_element_approved {
    background-color: #F4F8FF;
    border: 0.5px solid #7C90FF;
    border-radius: 5px;
    min-height: 30px;
    margin-top: 1px;
    margin-left: 3px;
    margin-right: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.bulk_orders_area_element {
    background-color: #EFEFEF;
    border: 0.5px solid #D6D6D6;
    border-radius: 5px;
    min-height: 30px;
    margin-top: 1px;
    margin-left: 3px;
    margin-right: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.bulk_order_icon_name {
    display: flex;
    min-width: 80px;
    margin-left: 4px;
    flex-direction: row;
    align-items: center;
}

.bulk_order_icon {
    margin-right: 10px;
}

.bulk_order_name {
    font-family: "SF-Pro-Rounded-regular";
    font-size: 12px;
    letter-spacing: calc((10 / 1000) * 1em);
    text-transform: capitalize;
    color: #5D5D5D;
}

.bulk_order_details {
    display: flex;
    min-width: 118px;
    margin-right: 10px;
    justify-content: space-between;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
}

.bulk_order_details div {
    min-width: 12px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
}

.bulk_order_time {
    text-transform: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video-inner-div {
    width: -webkit-fill-available;
    overflow: hidden;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #000000;
}

.ballet_action_button3 {
    width: -webkit-fill-available;
    min-height: 40px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 3px 3px rgb(0, 0, 0, 0.1);
    top: -9px;
    position: relative;
    background-color: #1D378B;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ballet_action_button3 svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.ballet_action_button2 {
    width: -webkit-fill-available;
    min-height: 40px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 3px 3px rgb(0, 0, 0, 0.1);
    top: -9px;
    position: relative;
    background-color: #6190F8;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ballet_action_button2 svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.ballet_action_button {
    width: -webkit-fill-available;
    min-height: 40px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 3px 3px rgb(0, 0, 0, 0.1);
    top: -9px;
    position: relative;
    background-color: #5D5D5D;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ballet_action_button svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.ballet_action_button_text {
    font-size: 12px;
    color: #F7F7F7;
    font-family: "SF-Pro-Rounded-light";
    letter-spacing: calc((10 / 1000) * 1em);
}