.barscan_down_area {
    padding-left: 30px;
    padding-right: 30px;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 19px;
    min-height: 240px;
}

.barscan_down_area video {
    background-color: #000000;
    border-radius: 10px;
    width: auto;
    position: relative;
}

.barscan_switch_area {
    width: max-content;
    position: relative;
    top: 40px;
    background-color: rgb(97, 144, 248, 50%);
    margin-left: 10px;
    margin-right: 10px;
    min-height: 30px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 999;
    align-self: center;
    padding-right: 75px;
}

.barscan_switch_button {
    margin-left: 10px;
    display: flex;
    margin-right: calc((11 / 100) * 100vw);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.barscan_description {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "SF-Pro-Rounded-light";
    font-size: 12px;
    color: #F7F7F7;
    letter-spacing: calc((10 / 1000) * 1em);
}

.barscan_description svg {
    margin-right: 10px;
}

.enter_bartext_area {
    min-height: 60px;
    background-color: #FAFAFA;
    width: -webkit-fill-available;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inner_scan_icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.inner_send_icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.inner_search_area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.inner_search_area_text {
    font-size: 18px;
    font-family: "SF-Pro-Rounded-regular";
    color: #5D5D5D;
    margin-right: 3px;
    height: 16px;
    margin-top: 3px;
    width: 50%;
    caret-color: #5D5D5D;
}

.inner_search_area_text:focus {
    border: none;
    outline: none;
    background-color: #FAFAFA;
}

#inner_search_area_input::placeholder {
    font-family: "SF-Pro-Rounded-light";
    font-size: 12px;
    color: #9C9C9C;
}

.inner_search_area_icon_o {
    font-size: 18px;
    font-family: "SF-Pro-Rounded-regular";
    color: #5D5D5D;
    margin-right: 3px;
}

.inner_search_area_icon_l {
    font-size: 18px;
    font-family: "SF-Pro-Rounded-light";
    color: #5D5D5D;
    text-transform: lowercase;
    margin-right: 1px;
}

.inner_search_area_icon {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 24px;
}